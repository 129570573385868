import './App.css';
import angel from "./img/anjooo.png";
import { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { BiLogoTelegram } from "react-icons/bi";
import { CiPill } from "react-icons/ci";
import { FaXTwitter } from "react-icons/fa6";
import { FaRegCopy } from "react-icons/fa";

function App() {
  const [question, setQuestion] = useState('');
  const [messages, setMessages] = useState([]);
  const [apiData, setApiData] = useState(null);
  const messageAreaRef = useRef(null);

  const accessKey = 'A1qQaAA9kdfnn4Mmn44bpoieIYHKkdghFKUD1978563llakLLLKdfslphgarcorc3haeogmmMNn243wf';

  const handleInputChange = (event) => {
    setQuestion(event.target.value);
  };

  const filterText = (text) => {
    const phrasesToRemove = [
      "Warm, Soothing Light Embraces You",
      // Adicione mais frases conforme necessário
    ];

    let filteredText = text;
    phrasesToRemove.forEach(phrase => {
      const regex = new RegExp(phrase, 'gi');
      filteredText = filteredText.replace(regex, '');
    });

    filteredText = filteredText.replace(/\*/g, '');
    filteredText = filteredText.replace(/\.\.\..*$/, '');
    return filteredText.trim();
  };

  const handleKeyPress = async (event) => {
    if (event.key === 'Enter' && question.trim() !== '') {
      setMessages((prevMessages) => [...prevMessages, { sender: 'user', text: question }]);

      try {
        const response = await axios.post('https://apitrumptalk.onrender.com/api/ask', { question });
        let answer = response.data.answer;
        answer = filterText(answer);

        setMessages((prevMessages) => [...prevMessages, { sender: 'angel', text: answer }]);
      } catch (error) {
        console.error("Error fetching response from AI:", error);
        setMessages((prevMessages) => [
          ...prevMessages,
          { sender: 'angel', text: 'Sorry, I could not find an answer at the moment.' }
        ]);
      }

      setQuestion('');
    }
  };

  const copyTokenCaToClipboard = () => {
    if (apiData && apiData.tokenCA) {
      navigator.clipboard.writeText(apiData.tokenCA)
        .then(() => {
          alert("Token has been copied!");
        })
        .catch((error) => {
          console.error("Erro ao copiar o Token CA:", error);
        });
    }
  };

  useEffect(() => {
    const fetchTokenData = async () => {
      try {
        const response = await axios.get('https://interca.onrender.com/api/purchaseData', {
          headers: {
            'x-access-key': accessKey,
          },
        });
        setApiData(response.data);
      } catch (error) {
        console.error('Erro ao buscar os dados do token:', error);
      }
    };

    fetchTokenData(); // Chamando a função sempre
  }, []);

  useEffect(() => {
    if (messageAreaRef.current) {
      messageAreaRef.current.scrollTop = messageAreaRef.current.scrollHeight;
    }
  }, [messages]);

  return (
    <div className="App">
      <div className="tokenName">
        {apiData ? apiData.tokenName : "ANGEL"}
      </div>
      <div className="envCa">
        <>CA:</>
        <div className="tokenCa">
          {apiData ? apiData.tokenCA : "Loading..."}
        </div>
        <div className='ponto'>...</div>
        <div className="copy" onClick={copyTokenCaToClipboard}>
          <FaRegCopy />
        </div>
      </div>
      <div className="chat">
        <div className="env-icons">
          <a href={apiData?.link} target="_blank" rel="noopener noreferrer">
            <CiPill className='ic' size={35} color='white' />
          </a>
          <a href={apiData?.twitterLink} target="_blank" rel="noopener noreferrer">
            <FaXTwitter className='ic' size={35} color='white' />
          </a>
          <a href={apiData?.telegramLink} target="_blank" rel="noopener noreferrer">
            <BiLogoTelegram className='ic' size={35} color='white' />
          </a>
        </div>
        <div className="env-logo">
          <img src={angel} alt="Angel" />
        </div>
        <div className="message-area" ref={messageAreaRef}>
          {messages.map((msg, index) => (
            <div
              key={index}
              className={`message ${msg.sender === 'user' ? 'user-message' : 'angel-message'}`}
            >
              {msg.text}
            </div>
          ))}
        </div>
        <div className="input-area">
          <input
            className='input'
            type="text"
            value={question}
            onChange={handleInputChange}
            onKeyPress={handleKeyPress}
            placeholder="Type your question and press Enter"
          />
        </div>
      </div>
    </div>
  );
}

export default App;
